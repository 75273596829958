.Select {
  width: 100%;
  margin-bottom: 5px;
  background: transparent;
  color: var(--color-offwhite);
  border-radius: 2px;
  font-size: 0.75em;
  min-width: 0;
}

.Select option {
  color: var(--color-offwhite);
  background: var(--color-black);
}

.Select optgroup {
  color: var(--color-offwhite);
  background: var(--color-black);
}