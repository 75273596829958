.RemoveFilterButton {
  color: var(--color-offwhite);
  font-size: 0.7em;
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
}

.RemoveFilterButton:hover{
  color: var(--color-button-delete);
}