.NearestNeighborSearch {
  position: absolute;
  z-index: 10;
  top: 65px;
  left: 10px;
  padding: 10px;
  border: 1px solid var(--color-darkgray);
  border-radius: 2px;
  background-color: var(--color-black);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  color: var(--color-offwhite);
  font-family: 'Open Sans';
  width: 200px;
  display: flex;
  flex-direction: column;
  box-sizing: unset;
}

.Title {
  font-size: 1em;
  font-weight: 600;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 5px;
  text-align: center;
  max-width: 250px;
  white-space: normal;
}

.Textarea {
  height: 200px;
  resize: none;
  outline: none;
  font-family: monospace;
}

.Controls {
  display: flex;
}

.SimilarityLabel {
  font-size: 0.8em;
  padding-top: 10px;
  text-align: center;
}