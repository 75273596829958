.JsmeEditor {
  position: absolute;
  top: 65px;
  z-index: 1;
  border: 1px solid var(--color-darkgray);
  border-radius: 2px;
  padding: 5px;
  background-color: var(--color-black);
}

.JsmeEditor.Right {
  right: 235px;
}

.JsmeEditor.Left {
  left: 235px;
}

.JsmeEditor .JsmeEditorContainer {
  position: relative;
}

.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="660"][height="660"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="660"][height="660"] {
  fill: var(--color-black);
}

.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"] {
  fill: var(--color-button-select);
  transform: translate(-8%, -18%);
}

.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"] + rect[width="690"][height="30"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"] + rect[width="690"][height="30"]+ rect[width="30"][height="690"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"] + rect[width="690"][height="30"]+ rect[width="30"][height="690"] + rect[width="30"][height="720"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"] + rect[width="690"][height="30"]+ rect[width="30"][height="690"] + rect[width="30"][height="720"] + rect[width="690"][height="30"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"] + rect[width="690"][height="30"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"] + rect[width="690"][height="30"]+ rect[width="30"][height="690"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"] + rect[width="690"][height="30"]+ rect[width="30"][height="690"] + rect[width="30"][height="720"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="660"][height="660"][fill="rgb(134,134,134)"] + rect[width="690"][height="30"]+ rect[width="30"][height="690"] + rect[width="30"][height="720"] + rect[width="690"][height="30"]{
  transform: translate(-8%, -18%);
}

.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g line,
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g g:nth-of-type(2),
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g g:nth-of-type(3),
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g polygon {
  stroke: var(--color-gray);
}

.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g text {
  fill: var(--color-gray);
}

.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect:nth-of-type(1),
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect:nth-of-type(1),
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(5) svg g rect:nth-of-type(1) {
  fill: var(--color-black);
}

.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(5) svg g text {
  fill: var(--color-gray);
}

.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="690"][height="30"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="30"][height="690"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="720"][height="30"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(3) svg g rect[width="30"][height="720"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="690"][height="30"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="30"][height="690"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="720"][height="30"],
.JsmeEditor .JsmeEditorContainer .jsa-resetDiv:nth-of-type(1) .jsa-resetDiv:nth-of-type(4) svg g rect[width="30"][height="720"] {
  fill: var(--color-gray);
}
