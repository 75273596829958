.CartIndicator {
  position: absolute;
  z-index: 9;
  pointer-events: none;
}

.CartIndicator .CrosshairX {
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  height: 2px;
  width: 100%;
  margin: auto;
  background-color: var(--color-offwhite);
}

.CartIndicator .CrosshairY {
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  width: 2px;
  height: 100%;
  margin: auto;
  background-color: var(--color-offwhite);
}
