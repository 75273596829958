.Selection {
  position: absolute;
  display: block;
  z-index: 10;
  width: 250px;
  bottom: 10px;
  left: 10px;
  padding: 0px;
  border: 1px solid var(--color-darkgray);
  border-radius: 2px;
  background-color: var(--color-offblack);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  color: var(--color-offwhite);
  font-family: 'Open Sans';
  box-sizing: content-box;
}

@media(max-width: 579px) {
  .Selection {
    display: none;
  }
}

.Controls {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
  height: 30px;
}

.TabToggle {
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-darkgray);
  background-color: var(--color-black);
}

.Toggle {
  margin: 0px 2px;
  font-size: 1.5em;
  color: var(--color-offwhite);
  opacity: 0.75;
  transition: 0.2s;
  border: none;
  background: transparent;
}

.Toggle:hover {
  opacity: 1.0;
}

.Toggle:focus {
  outline:none;
}

.Tab {
  height: 100%;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid var(--color-darkgray);
  border-right: 1px solid var(--color-darkgray);
  border-left: 1px solid var(--color-darkgray);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-offblack);
  cursor: pointer;
}

.Tab label {
  margin-bottom: 0;
}

.Tab.Inactive {
  border-bottom: 1px solid var(--color-darkgray);
  background-color: var(--color-black);
}

.Tab.Inactive:hover {
  background-color: var(--color-offblack);
  transition: 0.1s;
  color: var(--color-white);
}