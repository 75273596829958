.Controls {
  position: absolute;
  z-index: 10;
  padding: 2px;
  border: 1px solid var(--color-darkgray);
  border-radius: 2px;
  background-color: var(--color-offblack);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  color: var(--color-offwhite);
  font-family: 'Open Sans';
}

.Controls.Left{
  top: 10px;
  left: 10px;
}

.Controls.Right{
  top: 10px;
  right: 10px;
}

.Controls  button {
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  font-size: 1.2em;
  color: var(--color-offwhite);
  opacity: 0.75;
  transition: 0.2s;
}

.Controls button:hover {
  opacity: 1.0;
}

.Controls button:focus {
  box-shadow: none;
}

.Controls #MoreControls {
  display: inline-block;
}

.Controls .IconLabelButton .Label {
  position: absolute;
  font-size: 10px;
  bottom: 0;
  right: 14px;
}