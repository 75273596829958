.NearestNeighbors {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  min-height: 290px;
}

.Container {
  display: block;
  flex-direction: column;
}

.Container::-webkit-scrollbar {
  width: 5px;
}

.Container::-webkit-scrollbar-thumb {
  background-color: var(--color-offwhite);
  border-radius: 30px;
  opacity: 50%;
}

.CopySmilesLink {
  text-decoration: underline;
  align-self: center;
  font-size: 0.8em;
  border: none;
  background: transparent;
  color: var(--color-gray);
}

.CopySmilesLink:focus {
  outline: none;
}

.CopySmilesLink:hover {
  cursor: pointer;
  color: var(--color-white);
  transition: 0.1s;
}

.CopySmilesInput{
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.Controls {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  right: 0px;
  z-index: 1;
}

.EmptyContainer {
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EmptyLabel {
  color: #505050
}

.Navigation {
  color: var(--color-offwhite);
  opacity: 0.75;
  transition: 0.2s;
  border: none;
  background: transparent;
  padding: 0;
}

.Navigation:focus {
  outline: none;
}

.Navigation:hover {
  opacity: 1.0;
}

.Navigation.Next {
  margin-right: 5px;
}

.Navigation.Prev {
  margin-right: 5px;
}

.Info {
  font-size: 0.8em;
  margin-top: 10px;
  margin-left: 20px;
}

.Info label {
  margin-bottom: 0;
  font-weight: bold;
  margin-right: 5px;;
}

.InfoContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-flow: column;
}