.SubstructureFilter {
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
}

.Label{
  font-size: 0.8em;
  align-self: center;
  padding-bottom: 5px;
  font-weight: bold;
}
