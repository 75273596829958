.MqnSearch {
  position: absolute;
  z-index: 10;
  top: 65px;
  left: 10px;
  padding: 10px;
  border: 1px solid var(--color-darkgray);
  border-radius: 2px;
  background-color: var(--color-black);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  color: var(--color-offwhite);
  font-family: 'Open Sans';
  width: 200px;
  display: flex;
  flex-direction: column;
  box-sizing: unset;
}

.ControlsBottom {
  border: 0px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  justify-content: center;
  margin-top: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.MqnButton {
  width: calc(50% - 5px);
}

.Title {
  font-size: 1em;
  font-weight: 600;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 5px;
  text-align: center;
  max-width: 250px;
  white-space: normal;
  width: 100%;
}

.Input {
  font-size: 13px;
}

.DistanceLabel {
  font-size: 0.75em;
  display: flex;
  justify-content: space-between;
}