.Cart {
  display: flex;
  min-height: 420px;
  position: relative;
  flex-direction: column;
  padding: 5px;
}

.ControlsBottom {
  border: 0px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  justify-content: center;
  margin-top: auto;
  flex-wrap: wrap;
  justify-content: space-between;
}

.CartButton {
  width: calc(50% - 5px);
}
