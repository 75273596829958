.LoadingSpinner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: var(--color-offblack);
  opacity: 0.5;
}

.LdsDualRing {
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
}

.LdsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--color-offwhite);
  border-color: var(--color-offwhite) transparent var(--color-offwhite) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
