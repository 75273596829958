
.Descriptors {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 270px;
  overflow: hidden auto;
}

.Descriptors.Selected {
  max-height: 345px;
}

.Descriptors.Sticky {
  max-height: 75px;
  margin-top: 5px;
  margin-bottom: 0;
}

.Descriptors::-webkit-scrollbar {
  width: 5px;
}

.Descriptors::-webkit-scrollbar-thumb {
  background-color: var(--color-offwhite);
  border-radius: 30px;
  opacity: 50%;
}

.Container {
  position: relative;
  min-height: 225px;
}

.LabelContainer {
  display: flex;
  align-items: center;
}

.LabelTitle{
  text-overflow: ellipsis;
  margin-left: 20px;
  white-space: nowrap;
  font-size: 0.8em;
  font-weight: bold;
  overflow: hidden;
}

.LabelInfo {
  font-size: 0.8em;
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

.Divider {
  width: 80%;
}

.EmptyContainer {
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EmptyLabel {
  color: #505050
}