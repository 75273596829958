.FilterSearch {
  position: absolute;
  z-index: 10;
  top: 65px;
  right: 10px;
  padding: 10px;
  border: 1px solid var(--color-darkgray);
  border-radius: 2px;
  background-color: var(--color-offblack);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  color: var(--color-offwhite);
  font-family: 'Open Sans';
  width: 200px;
  display: flex;
  flex-direction: column;
  box-sizing: unset;
}

.FilterSearch h2 {
  font-size: 1em;
  font-weight: 600;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 5px;
  text-align: center;
  max-width: 250px;
  white-space: normal;
}

.FilterSearchWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
}

.FilterContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  position: relative;
}

.FilterSearchControls {
  display: flex;
  flex-direction: row;
}

.SelectedFilterContainer {
  border: 1px solid white;
  height: 260px;
  overflow-x: hidden;
  overflow-x: auto;
  text-align:justify;
}

.SelectedFilterContainer::-webkit-scrollbar {
  width: 5px;
}

.SelectedFilterContainer::-webkit-scrollbar-thumb {
  background-color: var(--color-offwhite);
  border-radius: 30px;
  opacity: 50%;
}

.FilterRemoveButton {
  color: var(--color-offwhite);
  font-size: 0.7em;
  position: absolute;
  right: 10px;
  top: 5px;
}

.FilterRemoveButton:hover{
  color: var(--color-button-delete);
}