.Tmap {
  position: absolute;
  top: var(--navbar-height);
  left: 0;
  width: 100%;
  height: calc(100% - var(--navbar-height));
  overflow: hidden;
}

.Tmap.Public {
  top: 0;
  height: 100%;
}