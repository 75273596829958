.SmilesInput {
  position: relative;
}

.SmilesInput input {
  padding-right: 30px;
  width: 100%;
  font-size: 13px;
}

.SmilesInput button {
  position: absolute;
  right: 2px;
  top: 3px;
  font-size: 13px;
  height: calc(100% - 6px);
  border: none;
  background: var(--color-black);
  color: var(--off-white);
  border-radius: 2px;
}

.SmilesInput button:focus {
  outline:none
}