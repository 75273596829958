.LegendElement {
  align-items: flex-start;
  display: flex;
  margin: 10px 0;
}

.ColorBox {
  width: 15px;
  height: 15px;
  border: solid 0px;
}
.LegendLabel {
  height: 15px;
  font-size: 0.7em;
  padding-left: 5px;
}

.LegendElementRange {
  position: relative;
  display: flex;
  flex: auto;
  align-items: flex-start;
  padding-bottom: 2px;
  padding-top: 2px;
  flex-direction: column;
}

.ColorStripe {
  width: 15px;
  height: 1px;
  border: solid 0px;
}

.LegendLabel {
  height: 15px;
  font-size: 0.7em;
  padding-left: 5px;
  display: flex;
  line-height: 1;
}

.LegendLabel.Max {
  position: absolute;
  top: 0px;
  margin-left: 15px;
  align-items: flex-start;
}

.LegendLabel.Min {
  position: absolute;
  bottom: 2px;
  margin-left: 15px;
  align-items: flex-end;
}

.CategorySeries {
  height: 103px;
  overflow-y: auto;
}

.CategorySeries::-webkit-scrollbar {
  width: 5px;
}

.CategorySeries::-webkit-scrollbar-thumb {
  background-color: var(--color-offwhite);
  border-radius: 30px;
  opacity: 50%;
}

.Limit {
  width: 15px;
  height: 10px;
  display: block;
  margin-right: 5px;
}