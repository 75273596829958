.Modal :global(.modal-content){
  background-color: transparent;
}

.Header {
  background-color: white;
  border-radius: .3rem .3rem 0 0;
}


.Body {
  border-radius: 0 0 .3rem .3rem;
  background-color: white;
}

.Body + .Header {
  margin-top: 10px;
}

.Button {
  width: 32px;
}

.Permissions {
  overflow: auto;
  max-height: 350px;
}

.LinkButton {
  background: no-repeat;
  border: none;
  padding: 0;
  color: blue;
}

.LinkButton:focus {
  outline: none;
}

.LinkButton:hover {
  background: no-repeat;
  border: none;
  padding: 0;
  color: lightblue;
}

.LinkInput {
  pointer-events: none;
}