.TextReader {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Title {
  font-size: 0.8em;
}

.Input {
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
}
