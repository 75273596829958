.Legend {
  width: 230px;
  position: absolute;
  z-index: 10;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  border: 1px solid var(--color-darkgray);
  border-radius: 2px;
  background-color: var(--color-offblack);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  color: var(--color-offwhite);
  font-family: 'Open Sans';
  box-sizing: unset;
}

.Container {
  display: flex;
  flex: auto;
  align-items: flex-start;
  flex-direction: column;
  min-height: 255px;
}

.Legend h2 {
  font-size: 1em;
  font-weight: 600;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 5px;
  text-align: center;
  max-width: 250px;
  white-space: normal;
}

.Toggle {
  margin: 0px 2px;
  color: var(--color-offwhite);
  opacity: 0.75;
  transition: 0.2s;
  border: none;
  background: transparent;
  margin-left: auto;
  font-size: 1.2em;
}

.Toggle:hover {
  opacity: 1.0;
}

.Toggle:focus {
  outline:none;
}