.TMAPInfo {
  position: absolute;
  z-index: 10;
  top: 65px;
  right: 10px;
  padding: 10px;
  border: 1px solid var(--color-darkgray);
  border-radius: 2px;
  background-color: var(--color-offblack);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  color: var(--color-offwhite);
  font-family: 'Open Sans';
  width: auto;
  display: flex;
  flex-direction: column;
  box-sizing: unset;
}

.TMAPInfo h2 {
  font-size: 1em;
  font-weight: 600;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 5px;
  text-align: center;
  max-width: 250px;
  white-space: normal;
}

.Label {
  font-size: 0.8em;
  font-style: italic;
}

.Info {
  font-size: 0.9em;
}

.Info + .Label {
  margin-top: 10px;
}