.HistogramRangeSelector {
  position: absolute;
  top: 65px;
  right: 235px;
  z-index: 1;
  border: 1px solid var(--color-darkgray);
  border-radius: 2px;
  background-color: var(--color-offblack);
  padding: 10px;
  color: var(--color-offwhite);
}

.Selecting {
  cursor: col-resize;
}

.HistogramContainer {
  width: 400px;
  margin: 0 auto;
}

.ImageContainer {
  width: 100%;
  position: relative;
}

.LoaderContainer {
  min-width: 400px;
  min-height: 223px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MouseHandler {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.MinLimit,
.MaxLimit {
  position: absolute;
  height: 100%;
  background-color: black;
  width: 2px;
  cursor: col-resize;
  z-index: 1;
}

.MinLimit::before {
  content: " ";
  position: absolute;
  border-top: 5px solid black;
  border-right: 5px solid transparent;
}

.MinLimit::after {
  content: " ";
  position: absolute;
  border-bottom: 5px solid black;
  border-right: 5px solid transparent;
  bottom: 0
}

.MaxLimit::before {
  content: " ";
  position: absolute;
  border-top: 5px solid black;
  border-left: 5px solid transparent;
  right: 0;
}

.MaxLimit::after {
  content: " ";
  position: absolute;
  border-bottom: 5px solid black;
  border-left: 5px solid transparent;
  bottom: 0;
  right: 0;
}

.Selection {
  position: absolute;
  height: 100%;
  pointer-events: none;
}

.MinShade,
.MaxShade {
  position: absolute;
  height: 100%;
  background-color: #FFFFFFAA;
  pointer-events: none;
}

.MinShade {
  left: 0;
}

.MaxShade {
  right: 0px;
}