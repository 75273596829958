
.Smiles {
  position: relative;
}

.EmptyContainer {
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Smiles.Mqn .EmptyContainer {
  height: 230px;
}

.EmptyLabel {
  color: #505050
}


.Tip {
  height: 250px;
  font-family: 'Open Sans';
  background-color: rgba(10, 10, 10, 1.0);
  border-radius: 2px;
  pointer-events: none;
  margin: 15px 0;
}

.Smiles.Mqn .Tip {
  height: 180px;
}

.Tip.SelectedTip {
  border: 1px solid var(--color-gray);
}

.SelectedTipText {
  position: relative;
  max-width: 250px;
  font-size: 0.8em;
  font-weight: bold;
  white-space: normal;
  line-break: normal;
}

.ControlsBottom {
  border: 0px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  justify-content: center;
  margin-top: auto;
}

.CopySmilesLink {
  text-decoration: underline;
  align-self: center;
  font-size: 0.8em;
  border: none;
  background: transparent;
  color: var(--color-gray);
}

.CopySmilesLink:focus {
  outline: none;
}

.CopySmilesLink:hover {
  cursor: pointer;
  color: var(--color-white);
  transition: 0.1s;
}

.CopySmilesInput{
  position: absolute;
  z-index: -1;
  opacity: 0;
}