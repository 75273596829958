.SeriesTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SeriesTitle h3 {
  width: 100%;
  font-size: 0.8em;
  max-width: 250px;
  white-space: normal;
  margin: 0.5em 0;
  line-height: normal;
}

.Controls {
  display: flex;
  width: 25%;
}

.AddToCart {
  width: 100%;
  display: flex;
  font-size: 0.7em;
  color: var(--color-offwhite);
  background: transparent;
  border: none;
  padding: unset;
}

.AddToCart:hover {
  color: var(--color-white);
}

.AddToCart:focus {
  outline: none;
}