.Button {
  font-family: Arial;
  margin-top: auto;
  color: var(--color-offwhite);
  border-radius: 2px;
  font-size: 0.75em;
  background: transparent;
}

.Button:disabled:hover {
  background: transparent;
  color: var(--color-offwhite);
  cursor: not-allowed;
}

.Small {
  line-height: normal;
}

.Large {
  padding: 3px 0;
}

.Block {
  width: 100%;
}

.Button:focus {
  outline:none
}

.Button.Danger:hover {
  transition: 0.2s;
  background-color: var(--color-button-delete);
  color: white;
}

.Button.Success:hover {
  transition: 0.2s;
  background-color: var(--color-button-confirm);
  color: white;
}

.Button:hover {
  transition: 0.2s;
  background-color: var(--color-button-select);
  color: white;
}