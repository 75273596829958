.NumericalFilter {
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
}

.InputContainer {
  display: flex;
  flex-direction: row;
}

.Input {
  width: 100%;
  min-width: 0;
  font-size: 13px;
  box-sizing: unset;
}

.FilterDivider {
  margin: 0 10px;
}

.Label{
  font-size: 0.8em;
  align-self: center;
  padding-bottom: 5px;
  font-weight: bold;
  margin-top: 10px;
}

.InvertHistogramContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}