.TabLinks {
  width: 100%;
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid var(--color-gray);
  margin: 5px 0;
}

.TabLink {
  opacity: 0.5;
  height: 15px;
  border: 0px;
  width: 100%;
  border-radius: 0px;
  border-left: 1px solid var(--color-gray);
  outline: none;
  background-color: var(--color-offblack);
  color: var(--color-offwhite);
  font-size: 13.333333px;
}

.TabLink:focus {
  outline: none;
}

.TabLink:first-child {
  border-left: 0px;
}

.TabLink.Active {
  opacity: 1;
}

.TabLink:hover {
  color: var(--color-white);
  transition: 0.1s;
}
