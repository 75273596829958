.NavigationControls {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  justify-content: center;
  border-bottom: 1px solid var(--color-gray);
}

.Navigation {
  margin: 0px 2px;
  font-size: 1.5em;
  color: var(--color-offwhite);
  opacity: 0.75;
  transition: 0.2s;
  background: transparent;
  border: none;
  padding: 0 5px;
  font-size: 1.2em;
}

.Navigation.Next {
  margin-right: auto;
}

.Navigation:hover {
  opacity: 1.0;
}

.Navigation:focus {
  outline: none;
}